import { useStaticQuery, graphql } from 'gatsby';

const GetSelectedCategories = () => {
	const data = useStaticQuery(graphql`
		{
			allWpCategory {
				edges {
					node {
						slug
						id
						name
						description
						wpParent {
							node {
								count
							}
						}
						acf {
							showInGallery
							featuredImage {
								localFile {
									childImageSharp {
										fixed {
											...GatsbyImageSharpFixed
										}
										fluid {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`);
	return data.allWpCategory.edges;
};

export default GetSelectedCategories;
