/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useKeenSlider } from 'keen-slider/react';
import Header from '../components/Header';
import GetSelectedCategories from '../hooks/getSelectedGalleries';
import Footer from '../components/Footer';
import { device } from '../lib/device';
import GalleryPageData from '../hooks/getGalleryPageContent';
import Seo from '../components/Seo';
import '../style/app.scss';

const GalleryTemplate = styled.div`
	min-height: calc(100vh - 160px);
	padding-top: 50px;
	.container {
		width: 100%;
		max-width: 1480px;
		position: relative;
		@media (max-width: 1680px) {
			width: 90%;
		}
		@media ${device.desktop} {
			width: 100%;
		}
		h1 {
			font-family: var(--font_harmoniasanspro_light);
			font-weight: var(--fw_light);
			font-size: 60px;
			color: var(--brand_black);
			text-align: center;
			margin: 0;
			margin-bottom: 60px;
			@media ${device.desktopHhd} {
				margin-bottom: 30px;
			}
			@media ${device.desktop} {
				margin: 0px 20px;
				font-size: 42px;
			}
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 40px 0;
			@media ${device.desktop} {
				padding: 20px;
			}
			li {
				transition: all 0.4s ease;
				margin-bottom: 20px;
				&:hover,
				&.is_active {
					a {
						transform: scale(1.1);

						transition: all 0.4s ease;
						.description {
							opacity: 1;
							color: var(--brand_blue);
							transition: all 0.3s ease;
						}
						img {
							transition: all 0.4s ease;
							filter: grayscale(0%);
						}
						h2 {
							color: var(--brand_blue);
							transition: all 0.4s ease;
						}
						h6 {
							border-bottom: 1px solid var(--brand_blue);
						}
					}
				}
				a {
					text-decoration: none;
					display: block;
					padding: 0 20px;
					transition: all 0.4s ease;
					color: var(--brand_blue);
					img {
						/* filter: grayscale(100%); */
						pointer-events: none;
						transition: all 0.4s ease;
					}
					h2 {
						font-family: var(--font_harmoniasanspro_light);
						font-weight: 100;
						font-size: 25px;
						margin: 0;
						color: var(--brand_black);
						padding-top: 20px;
						pointer-events: none;
						@media ${device.tablet} {
							font-size: 20px;
							color: var(--brand_blue);
							border-bottom: 1px solid var(--brand_blue);
							padding: 10px 0;
							text-align: center;
						}
					}
					.description {
						opacity: 0;
						max-height: 0;
						margin-bottom: 10px;
						padding-bottom: 10px;
						color: var(--brand_blue);
						font-size: 16px;
						font-family: var(--font_myriadpro_regular);
						font-weight: 400;
						pointer-events: none;
						transition: all 0.3s ease;
					}
					h6 {
						border-bottom: 1px solid var(--brand_black);
						pointer-events: none;
					}
				}
			}
		}
		.arrow {
			fill: var(--brand_blue);
			width: 30px;
			position: absolute;
			top: 50%;
			left: -40px;
			transform: translateY(-50%);
			cursor: pointer;
			@media ${device.desktop} {
				left: 0px;
				width: 18px;
			}
			&.arrow_right {
				left: auto;
				right: -40px;
				@media ${device.desktop} {
					right: 0;
				}
			}
		}
	}
`;

const Gallery = () => {
	const data = GalleryPageData();
	const galleryData = GetSelectedCategories();
	const [currentSlide, setCurrentSlide] = useState(0);

	let hoverElements;

	const filteredData = galleryData.filter((gallery) => gallery.node.wpParent === null);
	console.log(filteredData);
	const selectedCategories = filteredData.filter(function (cat) {
		return cat.node.acf.showInGallery === true;
	});
	console.log(selectedCategories);

	// init slider
	const [sliderRef, slider] = useKeenSlider({
		loop: true,
		slidesPerView: 5,
		spacing: 10,
		mode: 'snap',
		controls: true,
		breakpoints: {
			'(max-width: 768px)': {
				slidesPerView: 2,
				mode: 'free',
			},
		},
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		},
	});

	function handleHovers(e) {
		hoverElements = document.querySelectorAll('.single_slide');
		hoverElements.forEach((hoverElement) => {
			hoverElement.classList.remove('is_active');
		});
		e.target.closest('.single_slide').classList.add('is_active');
	}

	return (
		<>
			<Seo seo={data.seo} />

			<Header />
			<GalleryTemplate>
				<div className="container">
					<h1>{data.title}</h1>
					<ul ref={sliderRef} className="gallery_slider keen-slider">
						{selectedCategories.map((gallery, i) => (
							<li
								key={`id_${i}`}
								className={`keen-slider__slide single_slide number-slide${i + 1}`}
								onMouseOver={handleHovers}
							>
								<Link to={`/gallery/${gallery.node.slug}`}>
									<Img fluid={gallery.node.acf.featuredImage.localFile.childImageSharp.fluid} />
									<h2>{gallery.node.name}</h2>
									<div className="description">{gallery.node.description}</div>
								</Link>
							</li>
						))}
						{selectedCategories.map((gallery, i) => (
							<li
								key={`id_${i + 10}`}
								className={`keen-slider__slide single_slide number-slide${i + 20}`}
								onMouseOver={handleHovers}
							>
								<Link to={`/gallery/${gallery.node.slug}`}>
									<Img fluid={gallery.node.acf.featuredImage.localFile.childImageSharp.fluid} />
									<h2>{gallery.node.name}</h2>
									<div className="description">{gallery.node.description}</div>
								</Link>
							</li>
						))}
					</ul>
					{slider && (
						<>
							<ArrowLeft
								onClick={(e) => e.stopPropagation() || slider.prev()}
								disabled={currentSlide === 0}
							/>
							<ArrowRight
								onClick={(e) => e.stopPropagation() || slider.next()}
								disabled={currentSlide === slider.details().size - 1}
							/>
						</>
					)}
				</div>
			</GalleryTemplate>
			<Footer />
		</>
	);
};

function ArrowLeft(props) {
	const disabeld = props.disabled ? ' arrow--disabled' : '';
	return (
		<svg
			onClick={props.onClick}
			className={`arrow arrow_left${disabeld}`}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
		</svg>
	);
}

function ArrowRight(props) {
	const disabeld = props.disabled ? ' arrow--disabled' : '';
	return (
		<svg
			onClick={props.onClick}
			className={`arrow arrow_right${disabeld}`}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
		</svg>
	);
}

export default Gallery;
